.App {
  width: 100%;
}

html {
  height: 100%;
}

#Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#Intro {
  padding-top: 230px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-width: 100px;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  padding-bottom: 50px;
}

.Three-headings {
  overflow: hidden;
  border-right: solid rgb(203, 15, 93);
  white-space: nowrap;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  color: #000;
  font-family: ambroise-std;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 0.9em;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(203, 15, 93);
  }
}

.Name {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: ambroise-std;
  font-size: 130px;
  letter-spacing: -3px;
  line-height: 0.9em;
  color: #000;
}

.Linkedin {
  margin-left: 15px;
  width: 60px;
}
.Github {
  width: 105px;
}

.Mail {
  width: 60px;
  margin-right: 10px;
  margin-bottom: 4px;
  margin-left: 15px;
}

.Portfolio_img {
  padding-top: 20px;
  width: auto;
  height: 500px;
}

.Footer-links .Linkedin {
  width: 40px;
  margin-bottom: 1px;
}
.Footer-links .Github {
  width: 75px;
  opacity: 0.75;
}

.Footer-links .Mail {
  width: 40px;
  margin-bottom: 3px;
  margin-right: 4px;
  padding-left: 20px;
}

.Linkedin:hover,
.Github:hover,
.Mail:hover {
  opacity: 0.5;
}

.Bar-intro {
  margin-left: 10px;
  height: 3px;
  width: 180px;
  background-color: rgb(203, 15, 93);
}

.Bar {
  align-self: center;
  height: 3px;
  width: 180px;
  background-color: rgb(203, 15, 93);
}

.Short-bar {
  align-self: center;
  height: 3px;
  width: 120px;
  background-color: rgb(203, 15, 93);
}

#About-me,
#Projects,
#Resume,
#Contact-me,
#Footer {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
}

.professional-experience, .Resume, .Contact, .about-me {
  align-self: center;
  font-family: ambroise-std;
  font-size: 55px;
  letter-spacing: 1px;
  line-height: 0.9em;
  color: #000;
}
p {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  font-family: ambroise-std;
  font-weight: 80;
  font-size: 22px;
  line-height: 1.5em;
  width: 90vw;
  max-width: 1050px;
  min-width: 300px;
}

.Technology {
  text-align: left;
  align-self: center;
}

.FitMinded,
.Image-to-voice,
.Car-ify {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
}

.Resume-link {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-wrap: wrap;
}

.Try-demo,
.View-code,
.submit {
  align-self: center;
  font-family: Sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: white;
  border: 2px solid black;
  color: black;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 30px 5px 20px 5px;
  width: 200px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease 0s;
  outline: 0;
}

.Try-demo:hover,
.View-code:hover,
.submit:hover {
  background-color: black;
  color: white;
}
h2 {
  align-self: center;
  font-family: ambroise-std;
  font-size: 40px;
  color: #000;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

h3 {
  align-self: center;
  font-family: ambroise-std;
  font-size: 30px;
  color: #000;

}

h4 {
  font-family: ambroise-std;
  align-self: center;
  padding: 0%;
  margin: 0%;
}
.fit-images {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-self: center;
}

.openFinImage {
  align-self: center;
  width: 600px;
}

.imageMac,
.carImage {
  align-self: center;
  width: 800px;
  height: auto;
}

.fit,
.imageTo,
.Carify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.buttons {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

#formName {
  margin-top: 50px;
}

.feedback-input {
  align-self: center;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 25px;
  background-color: transparent;
  border: 2px solid;
  padding: 20px;
  margin-top: 20px;
  outline: 0;
  width: 400px;
}

textarea {
  height: 200px;
}

.feedback-input:focus {
  border-color: rgb(203, 15, 93);
}

.Footer-content {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.Footer-links {
  align-self: center;
}

span {
  padding-top: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  #Intro {
    padding-top: 180px;
  }
  .Three-headings {
    align-self: center;
    font-size: 12px;
  }
  .Name {
    align-self: center;
    text-align: center;
    margin-top: 0px;
    font-size: 70px;
  }
  .Bar-intro,
  .Social {
    align-self: center;
  }
  .Bar-intro {
    margin-left: 0px;
  }
  h1 {
    font-size: 40px;
  }
 
  p {
    font-size: 17px;
  }
  .Fit-img {
    width: 180px;
  }
  .imageMac,
  .carImage {
    width: 310px;
  }
  .feedback-input {
    width: 300px;
  }
}

@media only screen and (max-width: 580px) {
  #Intro {
    padding-top: 120px;
  }
  .Three-headings {
    font-size: 10px;
  }
  .Portfolio_img {
    height: 400px;
  }
}
