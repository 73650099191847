.Nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: white;
  padding-top: 20px;
  min-width: 100px;
  display: flex;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

.Logo {
  margin-left: 100px;
  margin-top: 10px;
  font-family: ambroise-std;
  font-size: 65px;
  line-height: 0.9em;
  color: #000;
  font-weight: bold;
  border: 4px solid black;
  transition: all 0.3s ease;
  padding: 10px;
  border-radius: 18px;
}

.Logo:hover {
  content: "";
  background-color: rgb(211, 128, 162);
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 80px;
}

ul {
  display: flex;
  align-items: center;
}

li {
  list-style-type: none;
  font-family: Sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: 18px;
  margin-left: 40px;
  color: #000;
}

a {
  color: #000;
  text-decoration: none;
}

.border {
  position: relative;
}

.border::after {
  content: "";
  display: block;
  width: 0;
  height: 10px;
  border-bottom: 3px solid rgb(203, 15, 93);
  transition: width 0.3s;
}

.border:hover::after {
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .Logo {
    font-size: 40px;
    margin-left: 25px;
    border: 3px solid;
    border-radius: 15px;
    align-items: baseline;
  }
  .menu {
    margin-right: 40px;
  }
  li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .Nav {
    padding-top: 15px;
    align-items: baseline;
  }

  .Logo {
    font-size: 30px;
    margin-left: 25px;
    border: 3px solid;
    border-radius: 15px;
    align-items: baseline;
  }
  li {
    font-size: 10px;
  }
}

@media only screen and (max-width: 550px) {
  .Nav {
    padding-top: 10px;
    align-items: baseline;
  }

  .Logo {
    font-size: 20px;
    margin-left: 20px;
    border-radius: 10px;
    border: 2px solid;
  }
  .menu {
    margin-right: 15px;
  }
  li {
    font-weight: normal;
    font-size: 10px;
    margin-left: 11px;
  }
}
